import React, { Component } from 'react';
import { Alert, Image } from "react-native";
import { Spinner, Thumbnail, Left, Right, Title, Card, CardItem, Body, Container, Header, Content, Button, Footer, FooterTab, Text, Icon } from 'native-base';
import * as Font from "expo-font";
//import { Ionicons } from '@expo/vector-icons';


import addeventicon from '../assets/addevent.png'
import phonebookicon from '../assets/phonebook.png'
import wwwicon from '../assets/www.png'
import mapicon from '../assets/mapicon.png'
import noteicon from '../assets/note.png'

import * as Linking from 'expo-linking';
import * as WebBrowser from 'expo-web-browser';

export default class OfferDetail extends Component {  
  state = {
    loading: true,    
   offerdetail: '',
   
  }

  async componentDidMount() {
    await Font.loadAsync({
    Roboto: require('native-base/Fonts/Roboto.ttf'),      
      Roboto_medium: require('native-base/Fonts/Roboto_medium.ttf'),
      //Roboto: require('../assets/Roboto.ttf'),      
      //Roboto_medium: require('../assets/Roboto_medium.ttf'),      
      //...Ionicons.font,
    })

    this._mounted = true;    
         
    var token=this.props.navigation.getParam('token');
    var domain=this.props.navigation.getParam('domain');        
    var imageurlhead=this.props.navigation.getParam('imageurlhead');    
    var city=this.props.navigation.getParam('city');       
    var id = this.props.navigation.getParam('id');   
    

    this.setState({ loading: false, token: token, domain: domain, imageurlhead: imageurlhead, 
      city: city, id: id,});

    await fetch('https://'+this.state.domain+'offers/'+id, {
     method: 'GET',
      headers: {'Authorization': 'Token '+token},
    })
    .then ((res) =>
    {if (res.status === 401)
       {
         Alert.alert ("Error", "Invalid Token.\nPlease sign in again.",            
         [            
          {text: 'OK', onPress: () => this.props.navigation.navigate('Home')},
        ],          
        )
         
    }
    else{
      if (this._mounted) {
    res.json()
      .then((data) => {
        this.setState({
          offerdetail: data, phone: data.phone, wwwurl: data.website}) // setState
      })   // then(data)   
      } //if mounted
      this._mounted = false;
    } // else
    }) // if and res
    .catch(error => {        
      Alert.alert ("Alert", "The server may be down.  Please try again in a few minutes.",            
        [            
         {text: 'OK', onPress: () => this.props.navigation.navigate('Home')},
       ],          
       )
    }) // catch error
    
  } // componentDidMount
 

    _pressCall=()=>{
      const url='tel://'+this.state.phone
      Linking.openURL(url)
  }

  _pressWWW=()=>{
    const url='https://'+this.state.wwwurl
    //Linking.openURL(url)
    WebBrowser.openBrowserAsync(url);
}

  componentWillUnmount(){
    //
    this._mounted = false;
    
  }

  //handleTouchItem = (customer) => {
  //  this.props.navigation.navigate("CustomerDetail", customer);
  //}
  render() {    
    
    if (this.state.loading) {
      return (
        <Container>
        <Header />
        <Content>
          <Spinner />
          <Spinner color='red' />
          <Spinner color='green' />
          <Spinner color='blue' />
        </Content>
      </Container>
      );
    }
    return (      
      
      <Container>
        <Header style={{height:45, paddingTop:0}}>
        <Button transparent
          onPress={() => this.props.navigation.pop()}>
            <Icon name='arrow-back' />            
          </Button>   
          <Left/>
        <Body>
                <Title>Deal / Offer</Title>
              </Body>
              <Right>
            <Button transparent
            onPress={() => this.props.navigation.navigate('Cities')}>
              <Icon name='home' />
            </Button>
          </Right>
          </Header>
            
            
        <Content>                

        <Card>
        <CardItem cardBody>
              <Image source={{uri: 'https://'+this.state.imageurlhead+this.state.offerdetail.image}}
              style={{height: 450, width: null , flex: 1, resizeMode: 'contain'}}/>
            </CardItem>
              <CardItem button>   
         <Left>
              <Thumbnail source={addeventicon} style={{ width: 35, height: 35 }}/>           
                    <Text>{ this.state.offerdetail.offer_name }</Text>
                     </Left>
              </CardItem>
        </Card>
        
        <Card>
                      <CardItem>   
                        <Left>
                      <Thumbnail source={mapicon} style={{ width: 35, height: 35 }}/>                  
                    <Text>{ this.state.offerdetail.brand }
                    {'\n'}{ this.state.offerdetail.address }
                    {'\n'}{ this.state.offerdetail.city }</Text>
                    </Left>
              </CardItem>
        </Card>
       
       <Card>
        <CardItem button onPress={() => Alert.alert ("Alert", "Save this number to Phonebook?",            
          [            
           {text: 'Yes', onPress: () => this._pressCall()
           .catch(err => {
            Alert.alert ("Error", "Can't open Dialer",)
          })},
           {text: '',},
           {text: 'Cancel',},
         ],          
         )}>   
         <Left>
        <Thumbnail source={phonebookicon} style={{ width: 35, height: 35 }}/>         
                        <Text>{ this.state.offerdetail.phone }</Text>
                    </Left>
              </CardItem>                   
        </Card>

        <Card>
        <CardItem button  onPress={() => this._pressWWW()}>    
           
         <Left>
        <Thumbnail source={wwwicon} style={{ width: 35, height: 35 }}/>         
                        <Text>{ this.state.offerdetail.website }</Text>
                    </Left>
                                   </CardItem>   
                    
        </Card>
        <Card>
                      <CardItem>   
                        <Left>
                      <Thumbnail source={noteicon} style={{ width: 30, height: 30 }}/>                          
                    <Text>{ this.state.offerdetail.note }</Text>
                     </Left>
              </CardItem>
        </Card>
       </Content>
        <Footer>
          <FooterTab> 
          <Button onPress={() => this.props.navigation.navigate("Choices")}
          >
                <Icon name='color-palette' />
                <Text>Choices</Text>
            </Button>

          </FooterTab>
        </Footer>
      </Container>
   );
  }
}
