//import { Ionicons } from '@expo/vector-icons';

import React, { Component } from 'react';
import { Spinner, Thumbnail, Left, Right, Title, Card, CardItem, Body, Container, Header, Content, Button, Footer, FooterTab, Text, Icon } from 'native-base';
import * as Font from "expo-font";
import { Alert, Image } from "react-native";
import * as Calendar from 'expo-calendar'
import * as Linking from 'expo-linking';
import * as WebBrowser from 'expo-web-browser';

import addeventicon from '../assets/addevent.png'
import phonebookicon from '../assets/phonebook.png'
import wwwicon from '../assets/www.png'
import mapicon from '../assets/mapicon.png'
import noteicon from '../assets/note.png'
import organizericon from '../assets/organizer.png'
import ticketicon from '../assets/ticket.png'

export default class EventDetail extends Component {  
  state = {
    loading: true,    
   eventdetail: '',     
  }

  async componentDidMount() {
    await Font.loadAsync({
    Roboto: require('native-base/Fonts/Roboto.ttf'),      
      Roboto_medium: require('native-base/Fonts/Roboto_medium.ttf'),
      //Roboto: require('../assets/Roboto.ttf'),      
      //Roboto_medium: require('../assets/Roboto_medium.ttf'),      
      //...Ionicons.font,
    })

    const { status } = await Calendar.requestCalendarPermissionsAsync();
      if (status === 'granted') {        
        const calendars = await Calendar.getCalendarsAsync();
        const calendar = calendars.find(calendar => calendar.id === '1');
        this.setState({mycalendar: calendar})        
      }

    this._mounted = true;
    
    var token=this.props.navigation.getParam('token');
    var domain=this.props.navigation.getParam('domain');        
    var imageurlhead=this.props.navigation.getParam('imageurlhead');   
    var city=this.props.navigation.getParam('city');    
    var id=this.props.navigation.getParam('id');   
    
  
    this.setState({ loading: false, token: token, domain: domain, imageurlhead: imageurlhead, city: city, 
      id: id, });

    await fetch('https://'+this.state.domain+'events/'+id, {
     method: 'GET',     
      headers: {'Authorization': 'Token '+token,},
    })
    .then ((res) =>
    {if (res.status === 401)
       {
         Alert.alert ("Error", "Invalid Token.\nPlease sign in again.",            
         [            
          {text: 'OK', onPress: () => this.props.navigation.navigate('Home')},
        ],          
        )
         
    }
    else{
      if (this._mounted) {
    res.json()
      .then((data) => {
        this.setState({
          eventdetail: data, phone: data.phone, wwwurl: data.website,                    
        }) // setState
      })   // then(data)   
      } //if mounted
      this._mounted = false;
    } // else
    }) // if and res
    .catch(error => {        
      Alert.alert ("Alert", "The server may be down.  Please contact your admin.",            
        [            
         {text: 'OK', onPress: () => this.props.navigation.navigate('Home')},
       ],          
       )
    }) // catch error
  
  } // componentDidMount

  _pressCall=()=>{
    const url='tel://'+this.state.phone
    Linking.openURL(url)      
}
  _pressWWW=()=>{
    const url='https://'+this.state.wwwurl
    //Linking.openURL(url)
    WebBrowser.openBrowserAsync(url);
}

async addevent() { const eventIdInCalendar = await Calendar.createEventAsync('1', {
  title: this.state.eventdetail.name,    
  //startDate: new Date('2020-08-20T07:00'),
  //endDate: new Date('2020-08-20T15:00'),
  startDate: new Date(this.state.eventdetail.start_date),
  endDate: new Date(this.state.eventdetail.end_date),
  timeZone: "Australia/Sydney",
})
Calendar.openEventInCalendar(eventIdInCalendar)// that will give the user the ability to access the event in phone calendar 
//setEventIdInCalendar(eventIdInCalendar)
  }

  componentWillUnmount(){
    //
    this._mounted = false;
    
  }

  //handleTouchItem = (customer) => {
  //  this.props.navigation.navigate("CustomerDetail", customer);
  //}
  render() {    
    
    if (this.state.loading) {
      return (
        <Container>
        <Header />
        <Content>
          <Spinner />
          <Spinner color='red' />
          <Spinner color='green' />
          <Spinner color='blue' />
        </Content>
      </Container>
      );
    }
    return (      
      
      <Container>
        <Header style={{height:45, paddingTop:0}}>
        <Button transparent
          onPress={() => this.props.navigation.pop()}>
            <Icon name='arrow-back' />            
          </Button>   
          <Left/>
        <Body>
                <Title> &nbsp;Event</Title>
              </Body>
              <Right>
            <Button transparent
            onPress={() => this.props.navigation.navigate('Cities')}>
              <Icon name='home' />
            </Button>
          </Right>
         </Header>
            
            
        <Content>        

        <Card>
        <CardItem cardBody>
              <Image source={{uri: 'https://'+this.state.imageurlhead+this.state.eventdetail.image}} 
              style={{height: 450, width: null , flex: 1, resizeMode: 'contain'}}/>
            </CardItem>

            <CardItem button onPress={() => Alert.alert ("Alert", "Save this Event to Calendar?",            
          [            
           {text: 'Yes', onPress: () => this.addevent()
           .catch(err => {
            Alert.alert ("Error", "Can't open Calendar",)
          })},
           {text: '',},
           {text: 'Cancel',},
         ],          
         )}>   
         <Left>
              <Thumbnail source={addeventicon} style={{ width: 35, height: 35 }}/>   
                    <Text>{ this.state.eventdetail.name }
                    {'\n'}{ this.state.eventdetail.start_date } - { this.state.eventdetail.end_date }</Text>
                     </Left> 
                   
              </CardItem>
        </Card>
        <Card>
                      <CardItem button onPress={() => this.props.navigation.push("VenueDetail", 
            {token:this.state.token, domain:this.state.domain, imageurlhead: this.state.imageurlhead,
             city: this.state.city, id:this.state.eventdetail.venue_id})}>      
              <Left>
                      <Thumbnail source={mapicon} style={{ width: 35, height: 35 }}/>                   
                    <Text>{ this.state.eventdetail.venue }
                    {'\n'}{ this.state.eventdetail.city }</Text>
                     </Left>
              </CardItem>
        </Card>
        
         <Card>
                      <CardItem>   
                      <Left>
                      <Thumbnail source={ticketicon} style={{ width: 30, height: 30 }}/>                  
                    <Text>Ticket : { this.state.eventdetail.ticket }
                    {'\n'}Registration : { this.state.eventdetail.registration }</Text>
                     </Left>
              </CardItem>
        </Card>

        <Card>
                      <CardItem>   
                        <Left>
                      <Thumbnail source={noteicon} style={{ width: 30, height: 30 }}/>                          
                    <Text>{ this.state.eventdetail.note }</Text>
                     </Left>
              </CardItem>
        </Card>

        <Card>
        <CardItem button onPress={() => Alert.alert ("Alert", "Save this number to Phonebook?",            
          [            
           {text: 'Yes', onPress: () => this._pressCall()
           .catch(err => {
            Alert.alert ("Error", "Can't open Dialer",)
          })},
           {text: '',},
           {text: 'Cancel',},
         ],          
         )}>   
         <Left>
        <Thumbnail source={phonebookicon} style={{ width: 35, height: 35 }}/>         
                        <Text>{ this.state.eventdetail.phone }</Text>
                    </Left>
              </CardItem>                   
        </Card>

        <Card>
        <CardItem button  onPress={() => this._pressWWW()}>    
          
         <Left>
        <Thumbnail source={wwwicon} style={{ width: 35, height: 35 }}/>         
                        <Text>{ this.state.eventdetail.website }</Text>
                    </Left>
                                   </CardItem>   
                    
        </Card>

        <Card>
                      <CardItem>   
                        <Left>
                      <Thumbnail source={organizericon} style={{ width: 30, height: 30 }}/>                          
                    <Text>Organizer : { this.state.eventdetail.organizer }</Text>
                     </Left>
              </CardItem>
        </Card>
</Content>
       <Footer>
          <FooterTab>
          <Button
          onPress={() => this.props.navigation.push("Events",
          {token:this.state.token, domain:this.state.domain, imageurlhead: this.state.imageurlhead, 
            city:this.state.city})}>
            <Icon name='ice-cream' />
                <Text>Events</Text>
            
          </Button>  

          <Button
          onPress={() => this.props.navigation.push("Choices",
          {token:this.state.token, domain:this.state.domain, imageurlhead: this.state.imageurlhead,
            city:this.state.city})}>
            <Icon name='color-palette' />
                <Text>Choices</Text>
            
          </Button>         
               
          </FooterTab>
        </Footer>
      </Container>
   );
  }
}
