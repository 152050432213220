import React, { Component } from 'react';
import { Alert, Image, FlatList } from "react-native";
import { Spinner, Item, Input, Card, CardItem, Body, Container, Header, Content, Button, Footer, FooterTab, Text, Icon } from 'native-base';
import * as Font from "expo-font";
//import { Ionicons } from '@expo/vector-icons';

export default class Webinars extends Component {  
  state = {
    loading: true,    
    webinars: [],
  }

  async componentDidMount() {
    await Font.loadAsync({
    Roboto: require('native-base/Fonts/Roboto.ttf'),      
      Roboto_medium: require('native-base/Fonts/Roboto_medium.ttf'),
      //Roboto: require('../assets/Roboto.ttf'),
      //Roboto_medium: require('../assets/Roboto_medium.ttf'),      
      //...Ionicons.font,
    })
    this._mounted = true;
    
    var token=this.props.navigation.getParam('token');
    var domain=this.props.navigation.getParam('domain');        
    var imageurlhead=this.props.navigation.getParam('imageurlhead');    
    var city=this.props.navigation.getParam('city');    
      
    this.setState({ loading: false, token: token, domain: domain, imageurlhead: imageurlhead, 
      city: city,});

    await fetch('https://'+this.state.domain+'webinars', {
      method: 'GET',
      headers: {'Authorization': 'Token '+token},   
    })
    .then((res) =>
    {if (res.status === 401)
       {
         Alert.alert ("Error", "Invalid Token.\nPlease sign in again.",            
         [            
          {text: 'OK', onPress: () => this.props.navigation.navigate('Home')},
        ],          
        )
         
    }
    else{
      if (this._mounted) {
    res.json()
      .then((data) => {
        this.setState({
          webinars: data
        },
        function() {                  //dont forget to add this for search function
          this.arrayholder = data;
        } 
        ) // setState
      })   // then(data)   
    } // if mounted
  } //else
  } // if (res.status
  ) // then(res)
    .catch(error => {        
      Alert.alert ("Alert", "The server may be down.  Please try again in a few minutes",            
        [            
         {text: 'OK', onPress: () => this.props.navigation.navigate('Home')},
       ],          
       )
    }) // catch error
  } // componentDidMount

  
  componentWillUnmount(){
    this._mounted = false;
  }
  
  SearchFilterFunction(text) {
    //passing the inserted text in textinput    
    const newData = this.arrayholder.filter(function(item) {
      //applying filter for the inserted text in search bar
    
    const itemCategory = item.category ? item.category.toLowerCase() : ''.toLowerCase();      
    const itemMonth = item.webinar_date ? item.webinar_date.toLowerCase() : ''.toLowerCase();  
    const itemName = item.name ? item.name.toLowerCase() : ''.toLowerCase(); 
    
    const textData = text.toLowerCase();
    return itemCategory.indexOf(textData) > -1 || itemMonth.indexOf(textData) > -1 || itemName.indexOf(textData) > -1;
    });
    
    this.setState({
      //setting the filtered newData on datasource
      //After setting the data it will automatically re-render the view      
      webinars: newData,   
    });
  }

  render() {    
    if (this.state.loading) {
      return (
        <Container>
        <Header />
        <Content>
          <Spinner />
          <Spinner color='red' />
          <Spinner color='green' />
          <Spinner color='blue' />
        </Content>
      </Container>
      );
    }
    return (      
      
      <Container>
        <Header searchBar rounded>
        <Item>
            <Icon name="ios-search" />
            <Input placeholder="webinar month / category" ref={'mysearch'} 
                  onChangeText={mysearch => this.SearchFilterFunction(mysearch)} />            
          </Item>
                
            </Header>    

            
        <Content>
        <Button block info>
        <Text>Online Events</Text>
        </Button>
        
        <FlatList
          numColumns={2}
            data={this.state.webinars}
            renderItem={({ item: webinar }) => (
              <Card style={{ flex: 0.5,}} key={webinar.id}>

<CardItem cardBody button onPress={() => this.props.navigation.push("WebinarDetail", 
            {token:this.state.token, domain:this.state.domain, imageurlhead: this.state.imageurlhead,
              city: this.state.city, id:webinar.id})}>

          <Image source={{uri: 'https://'+this.state.imageurlhead+webinar.image}}
          style={{height: 200, width: null, flex: 1}} />
            </CardItem>
               
            <CardItem button onPress={() => this.props.navigation.push("WebinarDetail", 
            {token:this.state.token, domain:this.state.domain, imageurlhead: this.state.imageurlhead,
              city: this.state.city, id:webinar.id})}>                
              <Body>                                 
                  <Text style={{fontSize:14, fontWeight: "bold", transform: [{ translateX: -10}]}}>{ webinar.webinar_date }
                  {"\n"}{webinar.category}</Text>
                
              </Body>
              
            </CardItem>

       </Card>      
            )} />    
       </Content>
        <Footer>
          <FooterTab>          
          
          <Button onPress={() => this.props.navigation.navigate("Choices")}
          >
                <Icon name='color-palette' />
                <Text>Choices</Text>
            </Button>
      
          </FooterTab>
        </Footer>
      </Container>
   );
  }
}
