import React, { Component } from 'react';
import { Spinner, Thumbnail, Left, Right, Title, Card, CardItem, Body, Container, Header, Content, Button, Footer, FooterTab, Text, Icon } from 'native-base';
import * as Font from "expo-font";
import { Alert, Image } from "react-native";
//import { Ionicons } from '@expo/vector-icons';

import addeventicon from '../assets/addevent.png'
import phonebookicon from '../assets/phonebook.png'
import wwwicon from '../assets/www.png'
//import mapicon from '../assets/mapicon.png'
import noteicon from '../assets/note.png'

import * as Calendar from 'expo-calendar'
import * as Linking from 'expo-linking';
import * as WebBrowser from 'expo-web-browser';

export default class OtherDetail extends Component {  
  state = {
    loading: true,    
   otherdetail: '',
   
  }

  async componentDidMount() {
    await Font.loadAsync({
    Roboto: require('native-base/Fonts/Roboto.ttf'),      
      Roboto_medium: require('native-base/Fonts/Roboto_medium.ttf'),
      //Roboto: require('../assets/Roboto.ttf'),      
      //Roboto_medium: require('../assets/Roboto_medium.ttf'),      
      //...Ionicons.font,
    })

    const { status } = await Calendar.requestCalendarPermissionsAsync();
    if (status === 'granted') {        
      const calendars = await Calendar.getCalendarsAsync();
      const calendar = calendars.find(calendar => calendar.id === '1');
      this.setState({mycalendar: calendar})        
    }

    this._mounted = true;    
         
    var token=this.props.navigation.getParam('token');
    var domain=this.props.navigation.getParam('domain');        
    var imageurlhead=this.props.navigation.getParam('imageurlhead');    
    var city=this.props.navigation.getParam('city');       
    var id = this.props.navigation.getParam('id');   
    
    this.setState({ loading: false, token: token, domain: domain, imageurlhead: imageurlhead, 
      city: city, id: id,});

    await fetch('https://'+this.state.domain+'otheritems/'+id, {
     method: 'GET',
      headers: {'Authorization': 'Token '+token},
    })
    .then ((res) =>
    {if (res.status === 401)
       {
         Alert.alert ("Error", "Invalid Token.\nPlease sign in again.",            
         [            
          {text: 'OK', onPress: () => this.props.navigation.navigate('Home')},
        ],          
        )
         
    }
    else{
      if (this._mounted) {
    res.json()
      .then((data) => {
        this.setState({
          otherdetail: data, phone: data.phone, wwwurl: data.website,
         }) // setState
      })   // then(data)   
      } //if mounted      
      this._mounted = false;
    } // else
    }) // if and res
    .catch(error => {        
      Alert.alert ("Alert", "The server may be down.  Please try again in a few minutes.",            
        [            
         {text: 'OK', onPress: () => this.props.navigation.navigate('Home')},
       ],          
       )
    }) // catch error
    
  } // componentDidMount
 

    _pressCall=()=>{
      const url='tel://'+this.state.phone
      Linking.openURL(url)
  }

  _pressWWW=()=>{
    const url='https://'+this.state.wwwurl
    //Linking.openURL(url)
    WebBrowser.openBrowserAsync(url);
}

async addevent() { const eventIdInCalendar = await Calendar.createEventAsync('1', {
  title: this.state.otherdetail.name,    
  //startDate: new Date('2020-08-20T07:00'),
  //endDate: new Date('2020-08-20T15:00'),
  startDate: new Date(this.state.otherdetail.date),
  endDate: new Date(this.state.otherdetail.date),
  timeZone: "Australia/Sydney",
})
Calendar.openEventInCalendar(eventIdInCalendar)// that will give the user the ability to access the event in phone calendar 
//setEventIdInCalendar(eventIdInCalendar)
  }


  componentWillUnmount(){
    //
    this._mounted = false;
    
  }

  //handleTouchItem = (customer) => {
  //  this.props.navigation.navigate("CustomerDetail", customer);
  //}
  render() {    
    
    if (this.state.loading) {
      return (
        <Container>
        <Header />
        <Content>
          <Spinner />
          <Spinner color='red' />
          <Spinner color='green' />
          <Spinner color='blue' />
        </Content>
      </Container>
      );
    }
    return (      
      
      <Container>
        <Header style={{height:45, paddingTop:0}}>
        <Button transparent
          onPress={() => this.props.navigation.pop()}>
            <Icon name='arrow-back' />            
          </Button>   
          <Left/>
        <Body>
                <Title>Misc</Title>
              </Body>
              <Right>
            <Button transparent
            onPress={() => this.props.navigation.navigate('Cities')}>
              <Icon name='home' />
            </Button>
          </Right>
          </Header>
            
            
        <Content>                

        <Card>
        <CardItem cardBody>
              <Image source={{uri: 'https://'+this.state.imageurlhead+this.state.otherdetail.image}}
               style={{height: 450, width: null , flex: 1, resizeMode: 'contain'}}/>
            </CardItem>
              <CardItem button onPress={() => Alert.alert ("Alert", "Save this Event to Calendar?",            
          [            
           {text: 'Yes', onPress: () => this.addevent()
           .catch(err => {
            Alert.alert ("Error", "Can't open Calendar",)
          })},
           {text: '',},
           {text: 'Cancel',},
         ],          
         )}>   
         <Left>
              <Thumbnail source={addeventicon} style={{ width: 35, height: 35 }}/>           
                    <Text>{ this.state.otherdetail.name }</Text>
                    </Left>
              </CardItem>
        </Card>
        
        
       
       <Card>
        <CardItem button onPress={() => Alert.alert ("Alert", "Save this number to Phonebook?",            
          [            
           {text: 'Yes', onPress: () => this._pressCall()
           .catch(err => {
            Alert.alert ("Error", "Can't open Dialer",)
          })},
           {text: '',},
           {text: 'Cancel',},
         ],          
         )}>   
         <Left>
        <Thumbnail source={phonebookicon} style={{ width: 35, height: 35 }}/>         
                        <Text>{ this.state.otherdetail.phone }</Text>
                        </Left>
              </CardItem>                   
        </Card>

        <Card>
        <CardItem button  onPress={() => this._pressWWW()}>    

         <Left>
        <Thumbnail source={wwwicon} style={{ width: 35, height: 35 }}/>         
                        <Text>{ this.state.otherdetail.website }</Text>
                        </Left>
                                   </CardItem>   
                    
        </Card>
        <Card>
                      <CardItem>   
                        <Left>
                      <Thumbnail source={noteicon} style={{ width: 30, height: 30 }}/>                          
                    <Text>{ this.state.otherdetail.note }</Text>
                    </Left>
              </CardItem>
        </Card>
       </Content>
        <Footer>
          <FooterTab>    
          <Button onPress={() => this.props.navigation.navigate("Choices")}
          >
                <Icon name='color-palette' />
                <Text>Choices</Text>
            </Button>
          </FooterTab>
        </Footer>
      </Container>
   );
  }
}
