//import { StatusBar } from 'expo-status-bar';
//import { Image, StyleSheet } from "react-native";
//import * as Expo from 'expo'
//import { Linking, Alert, View, FlatList, Platform, } from "react-native";
//import { Ionicons } from '@expo/vector-icons';

import React, { Component } from "react";
import { Spinner, Text, Container, Header, Title, Left, Right, Button, Body, Content, Footer, FooterTab } from "native-base";
import { createStackNavigator } from 'react-navigation-stack';
import { createAppContainer } from 'react-navigation';
import * as Font from "expo-font";
import * as WebBrowser from 'expo-web-browser';

import Cities from './src/cities.js'
import CityVenues from './src/venues.js'
import VenueDetail from './src/venuedetail.js'
import ChoicesScreen from './src/choices.js'
import CityEvents from './src/events.js'
import EventDetail from './src/eventdetail.js'
import CityLaunches from './src/launches.js'
import LaunchDetail from './src/launchdetail.js'
import CityOffers from './src/offers.js'
import OfferDetail from './src/offerdetail.js'
import CitySpecials from './src/specials.js'
import SpecialDetail from './src/specialdetail.js'
import Webinars from './src/webinars.js'
import WebinarDetail from './src/webinardetail.js'
import CityOthers from './src/others.js'
import OtherDetail from './src/otherdetail.js'
import Notices from './src/notices.js'
import NoticeDetail from './src/noticedetail.js'


class StartScreen extends Component {
  state = {
    loading: true,
    token: '2c012e6c3bced3c58ed597a898b522ee7fc20a01',   
    domain:  'vordemo.pythonanywhere.com/api/several/options/v1/',    
    imageurlhead: 'vordemo.pythonanywhere.com',
    //domain_country: '.pythonanywhere.com/api/several/v1/countries',    
    //token_country: ' '

  }
  
  async componentDidMount() {
    
      await Font.loadAsync({      
      Roboto: require('native-base/Fonts/Roboto.ttf'),      
      Roboto_medium: require('native-base/Fonts/Roboto_medium.ttf'),
      //Roboto: require('./assets/Roboto.ttf'),      
      //Roboto_medium: require('./assets/Roboto_medium.ttf'),
      Ubuntu: require('./assets/Ubuntu-Title.ttf'),      
      //...Ionicons.font,
      
    });
    this.setState({loading: false,});
  } // componentDidMount

   render() {    
    if (this.state.loading) {
      return (
        <Container>
        <Header />
        <Content>
          <Spinner />
          <Spinner color='red' />
          <Spinner color='green' />
          <Spinner color='blue' />
        </Content>
      </Container>
        //Expo.AppLoading
      );
    }
    return (        
      <Container>
          <Header>   
          <Left style={{flex: 1}} />               
          <Body style={{flex: 3,justifyContent: 'center'}}>
            <Title style={{fontFamily: 'Ubuntu', fontSize: 36, alignSelf:'center'}}>Vorrinize</Title>
          </Body>
          <Right style={{flex: 1}} />
        </Header>

            <Content>
            <Button transparant info />

            <Button full transparent light>
            <Text style={{fontSize: 16, color: '#000000' }}>Local Ad Campaign Platform</Text>
          </Button>
          <Button transparant info />

          <Button full transparent light>
            <Text style={{fontSize: 24, color: '#000000' }}>Demo</Text>
          </Button>

          <Button transparant info />
          <Button transparant info />

          <Button full info onPress={() => this.props.navigation.navigate('Cities',
          {token:this.state.token, domain:this.state.domain, imageurlhead: this.state.imageurlhead,})}>
            <Text>ENTER</Text>
          </Button>

          
          
          
        </Content>

        <Footer>
          <FooterTab>          
          <Button onPress={() => WebBrowser.openBrowserAsync('https://www.vorrinize.com/')}>
            <Text>&copy; Vorrinize</Text>
          </Button>          
          </FooterTab>
        </Footer>
      </Container>
   );
  }
}

  
const AppNavigator = createStackNavigator({
  StartScreen: {
    screen: StartScreen,
    navigationOptions: {
      title: "",
    //  headerTitleStyle: {
    //    fontFamily: 'Ubuntu',
        //fontWeight: 'bold',
    //    fontSize: 30, 
    headerLeft: () => null,
    headerStyle: {
      height: 0
      },      
    }},   

   
   
  Cities: {
    screen: Cities,
    navigationOptions: {
  title: '',
      headerLeft: () => null,
      headerStyle: {
        height: 0
      },      
    }},    

  Choices: {
    screen: ChoicesScreen,
    navigationOptions: {
  title: '',
      headerLeft: () => null,
      headerStyle: {
        height: 0
      },      
    }},   

    Venues: {
    screen: CityVenues,
    navigationOptions: {
  title: '',
      headerLeft: () => null,
      headerStyle: {
        height: 0
      },      
    }},   

  VenueDetail: {
    screen: VenueDetail,
    navigationOptions: {
  title: '',
      headerLeft: () => null,
      headerStyle: {
        height: 0
      },      
    }},   

  Events: {
    screen: CityEvents,
    navigationOptions: {
  title: '',
      headerLeft: () => null,
      headerStyle: {
        height: 0
      },      
    }},   

  EventDetail: {
    screen: EventDetail,
    navigationOptions: {
  title: '',
      headerLeft: () => null,
      headerStyle: {
        height: 0
      },      
    }},   

  Launches: {
    screen: CityLaunches,
    navigationOptions: {
  title: '',
      headerLeft: () => null,
      headerStyle: {
        height: 0
      },      
    }},   

  LaunchDetail: {
    screen: LaunchDetail,
    navigationOptions: {
  title: '',
      headerLeft: () => null,
      headerStyle: {
        height: 0
      },      
    }},   

  Offers: {
    screen: CityOffers,
    navigationOptions: {
  title: '',
      headerLeft: () => null,
      headerStyle: {
        height: 0
      },      
    }},   

    OfferDetail: {
      screen: OfferDetail,
      navigationOptions: {
    title: '',
        headerLeft: () => null,
        headerStyle: {
          height: 0
        },      
      }},   

      Specials: {
        screen: CitySpecials,
        navigationOptions: {
      title: '',
          headerLeft: () => null,
          headerStyle: {
            height: 0
          },      
        }},   

        SpecialDetail: {
          screen: SpecialDetail,
          navigationOptions: {
        title: '',
            headerLeft: () => null,
            headerStyle: {
              height: 0
            },      
          }},   
  
          Webinars: {
            screen: Webinars,
            navigationOptions: {
          title: '',
              headerLeft: () => null,
              headerStyle: {
                height: 0
              },      
            }},   
    
            WebinarDetail: {
              screen: WebinarDetail,
              navigationOptions: {
            title: '',
                headerLeft: () => null,
                headerStyle: {
                  height: 0
                },      
              }},   
      
               Others: {
                screen: CityOthers,
                navigationOptions: {
              title: '',
                  headerLeft: () => null,
                  headerStyle: {
                    height: 0
                  },      
                }},   
                    
                OtherDetail: {
                  screen: OtherDetail,
                  navigationOptions: {
                title: '',
                    headerLeft: () => null,
                    headerStyle: {
                      height: 0
                    },      
                  }},   
      
                  Notices: {
                    screen: Notices,
                    navigationOptions: {
                  title: '',
                      headerLeft: () => null,
                      headerStyle: {
                        height: 0
                      },      
                    }},   
                          
                    NoticeDetail: {
                      screen: NoticeDetail,
                      navigationOptions: {
                    title: '',
                        headerLeft: () => null,
                        headerStyle: {
                          height: 0
                        },      
                      }},   
                            
  });
  
export default createAppContainer(AppNavigator);

