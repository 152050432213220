//import { Ionicons } from '@expo/vector-icons';
//import { Alert, View, ActivityIndicator, FlatList, TouchableOpacity, StatusBar } from "react-native";
//import { SafeAreaView, Image } from "react-native";
//import { Item, Input, Right, } from 'native-base';

import React, { Component } from 'react';
import { Spinner, Thumbnail, Left, Title, Card, CardItem, Body, Container, Header, Content, Button, Footer, FooterTab, Text, Icon } from 'native-base';
import * as Font from "expo-font";

import eventicon from '../assets/events.png'
import launchicon from '../assets/launch.png'
import saleicon from '../assets/sale.png'
import onedayicon from '../assets/oneday.png'
import noticeicon from '../assets/notice1.jpg'
import onlineicon from '../assets/vr.png'
import miscicon from '../assets/misc.png'
import venueicon from '../assets/venue1.jpg'

export default class ChoicesScreen extends Component {  
  state = {
    loading: true,       
  }

  async componentDidMount() {
    await Font.loadAsync({
     Roboto: require('native-base/Fonts/Roboto.ttf'),      
      Roboto_medium: require('native-base/Fonts/Roboto_medium.ttf'),
      //Roboto: require('../assets/Roboto.ttf'),      
      //Roboto_medium: require('../assets/Roboto_medium.ttf'),      
      //...Ionicons.font,
    })
    this._mounted = true;
        
    var token=this.props.navigation.getParam('token');
    var domain=this.props.navigation.getParam('domain');        
    var imageurlhead=this.props.navigation.getParam('imageurlhead');    
    var city=this.props.navigation.getParam('city');    
    //var link=this.props.navigation.getParam('link');
  
    this.setState({ loading: false, token: token, domain: domain, imageurlhead: imageurlhead, city: city,});

    
  } // componentDidMount
  componentWillUnmount(){
    this._mounted = false;
  }
  //handleTouchItem = (customer) => {
  //  this.props.navigation.navigate("CustomerDetail", customer);
  //}
  render() {    
    if (this.state.loading) {
      return (
        <Container>
        <Header />
        <Content>
          <Spinner />
          <Spinner color='red' />
          <Spinner color='green' />
          <Spinner color='blue' />
        </Content>
      </Container>
      );
    }
    return (      
      
        <Container>
        <Header>
        
            <Button transparent>
              <Icon name='color-palette' />
            </Button>
        
        <Body>
                <Title>Choices</Title>
              </Body>              
            </Header>      
        <Content>
        <Card>      
      <CardItem button onPress={() => this.props.navigation.push("Events", 
            {token:this.state.token, domain:this.state.domain, imageurlhead: this.state.imageurlhead, 
              city:this.state.city })}> 
      <Left>
             <Thumbnail square source={eventicon} style={{ width: 25, height: 25 }}/>  
             <Body>
                <Text>Events &amp; Performances</Text>             
                </Body>
                </Left>            
            </CardItem>           
        </Card>
     
        <Card>      
      <CardItem button onPress={() => this.props.navigation.push("Launches", 
            {token:this.state.token, domain:this.state.domain, imageurlhead: this.state.imageurlhead, 
              city:this.state.city})}>   
      <Left>
            <Thumbnail square source={launchicon} style={{ width: 25, height: 25 }}/>
            <Body>
                <Text>New Launches &amp; Openings</Text>             
                </Body></Left>
            </CardItem>           
        </Card>

        <Card>      
      <CardItem button onPress={() => this.props.navigation.push("Offers", 
            {token:this.state.token, domain:this.state.domain, imageurlhead: this.state.imageurlhead, 
              city:this.state.city})}>    
      <Left> 
      <Thumbnail square source={saleicon} style={{ width: 25, height: 25 }}/>    
      <Body>
                <Text>Deals &amp; Offers</Text>             
                </Body>
                </Left>
            </CardItem>           
        </Card>

        <Card>      
      <CardItem button onPress={() => this.props.navigation.push("Specials", 
            {token:this.state.token, domain:this.state.domain, imageurlhead: this.state.imageurlhead, 
              city:this.state.city})}>   
      <Left>      
      <Thumbnail square source={onedayicon} style={{ width: 25, height: 25 }}/>  
      <Body>    
                <Text>1-Day Specials</Text>  
                </Body>
                </Left>          
            </CardItem>           
        </Card>

        <Card>      
      <CardItem button onPress={() => this.props.navigation.push("Webinars", 
            {token:this.state.token, domain:this.state.domain, imageurlhead: this.state.imageurlhead, 
              city:this.state.city})}> 
      <Left>     
      <Thumbnail square source={onlineicon} style={{ width: 25, height: 25 }}/>   
      <Body>   
                <Text>Online Events &amp; Webinars</Text>   
                </Body>
                </Left>        
            </CardItem>           
        </Card>

        <Card>      
      <CardItem button onPress={() => this.props.navigation.push("Others", 
            {token:this.state.token, domain:this.state.domain, imageurlhead: this.state.imageurlhead, 
            city:this.state.city})}>  
      <Left>     
      <Thumbnail square source={miscicon} style={{ width: 25, height: 25 }}/>  
      <Body>    
                <Text>Miscellaneous</Text>      
                </Body>
                </Left>   
            </CardItem>           
        </Card>

        <Card>      
      <CardItem button onPress={() => this.props.navigation.push("Notices", 
            {token:this.state.token, domain:this.state.domain, imageurlhead: this.state.imageurlhead, 
              city:this.state.city})}>  
      <Left>     
      <Thumbnail square source={noticeicon} style={{ width: 25, height: 25 }}/>  
      <Body>      
                <Text>Notices</Text>     
                </Body>
                </Left>        
            </CardItem>           
        </Card>

        <Card>      
      <CardItem button onPress={() => this.props.navigation.push("Venues", 
            {token:this.state.token, domain:this.state.domain, imageurlhead: this.state.imageurlhead, 
              city:this.state.city})}>  
      <Left>     
      <Thumbnail square source={venueicon} style={{ width: 25, height: 25 }}/>  
      <Body>      
                <Text>Venues</Text>     
                </Body>
                </Left>        
            </CardItem>           
        </Card>
        
       </Content>
       <Footer>
          <FooterTab>          
          <Button
          onPress={() => this.props.navigation.navigate("Cities")}>
            <Icon name='home' />
            <Text>Cities</Text>
          </Button>          
          </FooterTab>
        </Footer>
      </Container>
      
   );
  }
}
