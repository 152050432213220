import React, { Component } from 'react';
import { Spinner, Left, Right, Title, Card, CardItem, Body, Container, Header, Content, Button, Footer, FooterTab, Text, Icon } from 'native-base';
import * as Font from "expo-font";
import { Alert, Image, FlatList } from "react-native";
//import { Ionicons } from '@expo/vector-icons';

export default class VenueDetail extends Component {  
  state = {
    loading: true,    
   venuedetail: {
      //name: '',      
      events: [],            
  }
  }

  async componentDidMount() {
    await Font.loadAsync({
    Roboto: require('native-base/Fonts/Roboto.ttf'),      
      Roboto_medium: require('native-base/Fonts/Roboto_medium.ttf'),
      //Roboto: require('../assets/Roboto.ttf'),      
      //Roboto_medium: require('../assets/Roboto_medium.ttf'),      
      //...Ionicons.font,
    })
    this._mounted = true;
        
    var token=this.props.navigation.getParam('token');
    var domain=this.props.navigation.getParam('domain');        
    var imageurlhead=this.props.navigation.getParam('imageurlhead'); 
    var city=this.props.navigation.getParam('city'); 
    var id=this.props.navigation.getParam('id'); 
    
    this.setState({ loading: false, token: token, domain: domain, imageurlhead: imageurlhead, 
      city: city, id: id,});

    await fetch('https://'+this.state.domain+'venues/'+this.state.id, {
      method: 'GET',
      headers: {'Authorization': 'Token '+token},     
    })
    .then((res) =>
    {if (res.status === 401)
       {
         Alert.alert ("Error", "Invalid Token.\nPlease sign in again.",            
         [            
          {text: 'OK', onPress: () => this.props.navigation.navigate('Home')},
        ],          
        )
         
    }
    else{
      if (this._mounted) {
    res.json()
      .then((data) => {
        this.setState({
          venuedetail: data
        }) // setState
      })   // then(data)   
      } // if mounted
    this._mounted = false;
    } // else
    }) // if and res
    .catch(error => {        
      Alert.alert ("Alert", "The server may be down.  Please contact your admin.",            
        [            
         {text: 'OK', onPress: () => this.props.navigation.navigate('Home')},
       ],          
       )
    }) // catch error
  } // componentDidMount

   componentWillUnmount(){
    this._mounted = false;
  }
  //handleTouchItem = (customer) => {
  //  this.props.navigation.navigate("CustomerDetail", customer);
  //}
  render() {    
    if (this.state.loading) {
      return (
        <Container>
        <Header />
        <Content>
          <Spinner />
          <Spinner color='red' />
          <Spinner color='green' />
          <Spinner color='blue' />
        </Content>
      </Container>
      );
    }
    return (      
      
      <Container>
        <Header style={{height:45, paddingTop:0}}>
        <Button transparent
          onPress={() => this.props.navigation.pop()}>
            <Icon name='arrow-back' />            
          </Button>   
          <Left/>
        <Body>
                <Title>Venue</Title>
              </Body>

              <Right>
            <Button transparent
            onPress={() => this.props.navigation.navigate('Cities')}>
              <Icon name='home' />
            </Button>
          </Right>
         </Header>
            
            
        <Content>        

        <Card>
        <CardItem cardBody>
              <Image source={{uri: 'https://'+this.state.imageurlhead+this.state.venuedetail.image}}
              style={{height: 450, width: null , flex: 1, resizeMode: 'contain'}}/>
            </CardItem>
              <CardItem>                    
                    <Text>{ this.state.venuedetail.name }
                    {'\n'}{ this.state.venuedetail.address }, { this.state.venuedetail.city }
                    {'\n'}{ this.state.venuedetail.phone }</Text>
              </CardItem>
        </Card>
        <Button block info>
              <Text>Events at { this.state.venuedetail.name }</Text>
        </Button>

        <FlatList
          numColumns={2}
            data={this.state.venuedetail.events}
            renderItem={({ item: event }) => (
              <Card style={{ flex: 0.5,}} key={event.id}>

<CardItem cardBody button onPress={() => this.props.navigation.push("EventDetail", 
            {token:this.state.token, domain:this.state.domain, imageurlhead: this.state.imageurlhead,
              city: this.state.city, id:event.id})}>

          <Image source={{uri: 'https://'+this.state.imageurlhead+event.image}}
          style={{height: 200, width: null, flex: 1}} />
            </CardItem>
               
            <CardItem button onPress={() => this.props.navigation.push("EventDetail", 
            {token:this.state.token, domain:this.state.domain, imageurlhead: this.state.imageurlhead,
              city: this.state.city, id:event.id})}>                
              <Body>                                 
                  <Text style={{fontSize:14, fontWeight: "bold", transform: [{ translateX: -10}]}}>{ event.name }
                  {"\n"}{event.start_date}</Text>
                
              </Body>
              
            </CardItem>

       </Card>      
            )} />  



       </Content>
        <Footer>
          <FooterTab>         
            
          <Button onPress={() => this.props.navigation.push("Venues",
          {token:this.state.token, domain:this.state.domain, imageurlhead: this.state.imageurlhead, 
            city:this.state.city})}>
            <Icon name='ice-cream' />
                <Text>Venues</Text>
            
          </Button> 

          <Button onPress={() => this.props.navigation.navigate("Choices")}
          >
                <Icon name='color-palette' />
                <Text>Choices</Text>
            </Button>
                  
          </FooterTab>
        </Footer>
      </Container>
   );
  }
}
