//import { SafeAreaView, Image } from "react-native";
//import { Ionicons } from '@expo/vector-icons';

import React, { Component } from 'react';
import { Alert, Image, FlatList, LogBox  } from "react-native";
import { Spinner, Item, Input, Card, CardItem, Body, Container, Header, Content, Button, Footer, FooterTab, Text, Icon } from 'native-base';
import * as Font from "expo-font";

export default class CityEvents extends Component {  
  state = {
    loading: true,    
    
    events: [],
  }

  async componentDidMount() {
    
    await Font.loadAsync({
    Roboto: require('native-base/Fonts/Roboto.ttf'),      
      Roboto_medium: require('native-base/Fonts/Roboto_medium.ttf'),
      //Roboto: require('../assets/Roboto.ttf'),      
      //Roboto_medium: require('../assets/Roboto_medium.ttf'),      
      //...Ionicons.font,
    })
    this._mounted = true;

    var token=this.props.navigation.getParam('token');
    var domain=this.props.navigation.getParam('domain');        
    var imageurlhead=this.props.navigation.getParam('imageurlhead');    
    var city=this.props.navigation.getParam('city');   
    
  
    this.setState({ loading: false, token: token, domain: domain, imageurlhead: imageurlhead, city: city,});

    await fetch('https://'+this.state.domain+'events/'+this.state.city, {
      method: 'GET',
      headers: {'Authorization': 'Token '+token,},      
    }, {'cache':'no-store'})
    .then((res) =>
    {if (res.status === 401)
       {
         Alert.alert ("Error", "Invalid Token.\nPlease sign in again.",            
         [            
          {text: 'OK', onPress: () => this.props.navigation.navigate('Home')},
        ],          
        )
         
    }
    else{
      if (this._mounted) {
    res.json()
      .then((data) => {
        this.setState({
          events: data
        },
        function() {                  //dont forget to add this for search function
          this.arrayholder = data;
        } 
        ) // setState
      })   // then(data)   
      } // if this mounted
      this._mounted = false;
    } // else
    }) // if and then(res)
    .catch(error => {        
      Alert.alert ("Alert", "The server may be down.  Please contact your admin.",            
        [            
         {text: 'OK', onPress: () => this.props.navigation.navigate('Home')},
       ],          
       )
    }) // catch error
  } // componentDidMount

  
  componentWillUnmount(){
    this._mounted = false;
  }
  
  //handleTouchItem = (customer) => {
  //  this.props.navigation.navigate("CustomerDetail", customer);
  //}

  SearchFilterFunction(text) {
    //passing the inserted text in textinput    
    const newData = this.arrayholder.filter(function(item) {
      //applying filter for the inserted text in search bar
    
    const itemVenue = item.venue ? item.venue.toLowerCase() : ''.toLowerCase();      
    const itemMonth = item.start_date ? item.start_date.toLowerCase() : ''.toLowerCase();  
    const itemEventName = item.name ? item.name.toLowerCase() : ''.toLowerCase(); 
    
    const textData = text.toLowerCase();
    return itemVenue.indexOf(textData) > -1 || itemMonth.indexOf(textData) > -1 || itemEventName.indexOf(textData) > -1;
    });
    
    this.setState({
      //setting the filtered newData on datasource
      //After setting the data it will automatically re-render the view      
      events: newData,   
    });
  }


  render() {    
    if (this.state.loading) {
      return (
        <Container>
        <Header />
        <Content>
          <Spinner />
          <Spinner color='red' />
          <Spinner color='green' />
          <Spinner color='blue' />
        </Content>
      </Container>
      );
    }
    return (      
      
      <Container style={{flex: 1}}>
       <Header searchBar rounded>
        <Item>
            <Icon name="ios-search" />
            <Input placeholder="event name / month" ref={'mysearch'} 
                  onChangeText={mysearch => this.SearchFilterFunction(mysearch)}/>            
          </Item>
                
            </Header>  

            
        <Content>
        <Button block info>
        <Text>All Events in { this.state.city }</Text>
        
        </Button>        
        
        <FlatList
          numColumns={2}
            data={this.state.events}
            renderItem={({ item: event }) => (
              <Card style={{ flex: 0.5,}} key={event.id}>

<CardItem cardBody button onPress={() => this.props.navigation.push("EventDetail", 
            {token:this.state.token, domain:this.state.domain, imageurlhead: this.state.imageurlhead,
              city: this.state.city, id:event.id})}>

          <Image source={{uri: 'https://'+this.state.imageurlhead+event.image}}
          style={{height: 200, width: null, flex: 1}} />
            </CardItem>
               
            <CardItem button onPress={() => this.props.navigation.push("EventDetail", 
            {token:this.state.token, domain:this.state.domain, imageurlhead: this.state.imageurlhead,
              city: this.state.city, id:event.id})}>                
              <Body>                                 
                  <Text style={{fontSize:12, fontWeight: "bold", transform: [{ translateX: -10}]}}>{ event.name }
                  {"\n"}{event.start_date}</Text>
                
              </Body>
              
            </CardItem>

       </Card>      
            )} />    
              
       </Content>
       <Footer>
          <FooterTab>          
          <Button onPress={() => this.props.navigation.navigate("Choices")}>
            <Icon name='color-palette' />
            <Text>Choices</Text>
          </Button>          
          </FooterTab>
        </Footer>
      </Container>
   );
  }
}
