import React, { Component } from 'react';
import { Spinner, Thumbnail, Left, Right, Title, Card, CardItem, Body, Container, Header, Content, Button, Footer, FooterTab, Text, Icon } from 'native-base';
import * as Font from "expo-font";
import { Alert, Image, } from "react-native";
//import { Ionicons } from '@expo/vector-icons';

import addeventicon from '../assets/addevent.png'
//import phonebookicon from '../assets/phonebook.png'
import wwwicon from '../assets/www.png'
//import mapicon from '../assets/mapicon.png'
import noteicon from '../assets/note.png'
import ticketicon from '../assets/ticket.png'
import organizericon from '../assets/organizer.png'

import * as Calendar from 'expo-calendar'
//import * as Linking from 'expo-linking';
import * as WebBrowser from 'expo-web-browser';

export default class WebinarDetail extends Component {  
  state = {
    loading: true,    
   webinardetail: '',
   
  }

  async componentDidMount() {
    await Font.loadAsync({
    Roboto: require('native-base/Fonts/Roboto.ttf'),      
      Roboto_medium: require('native-base/Fonts/Roboto_medium.ttf'),
      //Roboto: require('../assets/Roboto.ttf'),      
      //Roboto_medium: require('../assets/Roboto_medium.ttf'),      
      //...Ionicons.font,
    })
    

    const { status } = await Calendar.requestCalendarPermissionsAsync();
      if (status === 'granted') {        
        const calendars = await Calendar.getCalendarsAsync();
        const calendar = calendars.find(calendar => calendar.id === '1');
        this.setState({mycalendar: calendar})        
      }

    this._mounted = true;    
         
    var token=this.props.navigation.getParam('token');
    var domain=this.props.navigation.getParam('domain');        
    var imageurlhead=this.props.navigation.getParam('imageurlhead');    
    var city=this.props.navigation.getParam('city');       
    var id = this.props.navigation.getParam('id');   
   
    this.setState({ loading: false, token: token, domain: domain, imageurlhead: imageurlhead, 
      city: city, id: id,});

    await fetch('https://'+this.state.domain+'webinars/'+id, {
     method: 'GET',
      headers: {'Authorization': 'Token '+token},
    })
    .then ((res) =>
    {if (res.status === 401)
       {
         Alert.alert ("Error", "Invalid Token.\nPlease sign in again.",            
         [            
          {text: 'OK', onPress: () => this.props.navigation.navigate('Home')},
        ],          
        )
         
    }
    else{
      if (this._mounted) {
    res.json()
      .then((data) => {
        this.setState({
          webinardetail: data, wwwurl: data.website,
        }) // setState
      })   // then(data)   
      } //if mounted
      this._mounted = false;
    } // else
    }) // if and res
    .catch(error => {        
      Alert.alert ("Alert", "The server may be down.  Please try again in a few minutes.",            
        [            
         {text: 'OK', onPress: () => this.props.navigation.navigate('Home')},
       ],          
       )
    }) // catch error
    
  } // componentDidMount
 

  async addevent() { const eventIdInCalendar = await Calendar.createEventAsync('1', {
    title: this.state.webinardetail.name,    
    //startDate: new Date("2021-08-20"),
    //endDate: new Date("2021-08-20"),
    startDate: new Date(this.state.webinardetail.webinar_date),
    endDate: new Date(this.state.webinardetail.webinar_date),
    timeZone: "Australia/Sydney",
  })
  Calendar.openEventInCalendar(eventIdInCalendar)// that will give the user the ability to access the event in phone calendar 
  //setEventIdInCalendar(eventIdInCalendar)
    }


  _pressWWW=()=>{
    const url='https://'+this.state.wwwurl
    //Linking.openURL(url)
    WebBrowser.openBrowserAsync(url);
}

  componentWillUnmount(){
    //
    this._mounted = false;
    
  }
  
  //handleTouchItem = (customer) => {
  //  this.props.navigation.navigate("CustomerDetail", customer);
  //}
  render() {    
    
    if (this.state.loading) {
      return (
        <Container>
        <Header />
        <Content>
          <Spinner />
          <Spinner color='red' />
          <Spinner color='green' />
          <Spinner color='blue' />
        </Content>
      </Container>
      );
    }
    return (      
      
      <Container>
        <Header style={{height:45, paddingTop:0}}>
        <Button transparent
          onPress={() => this.props.navigation.pop()}>
            <Icon name='arrow-back' />            
          </Button>   
          <Left/>
        <Body>
                <Title>Online</Title>
              </Body>
              <Right>
            <Button transparent
            onPress={() => this.props.navigation.navigate('Cities')}>
              <Icon name='home' />
            </Button>
          </Right>
          </Header>
            
            
        <Content>                

        <Card>
        <CardItem cardBody>
              <Image source={{uri: 'https://'+this.state.imageurlhead+this.state.webinardetail.image}}
              style={{height: 450, width: null, flex: 1, resizeMode: 'contain'}}/>
            </CardItem>
            <CardItem button onPress={() => Alert.alert ("Alert", "Save this Event to Calendar?",            
          [            
           {text: 'Yes', onPress: () => this.addevent()
           .catch(err => {
            Alert.alert ("Error", "Can't open Calendar",)
          })},
           {text: '',},
           {text: 'Cancel',},
         ],          
         )}>   
         <Left>
              <Thumbnail source={addeventicon} style={{ width: 35, height: 35 }}/>           
                    <Text>{ this.state.webinardetail.name }
                    {'\n'}{ this.state.webinardetail.webinar_date }</Text>
                    </Left>
              </CardItem>
        </Card>
                
        <Card>
                      <CardItem>   
                      <Left>
                      <Thumbnail source={ticketicon} style={{ width: 30, height: 30 }}/>                  
                    <Text>Ticket : { this.state.webinardetail.ticket }
                    {'\n'}Registration : { this.state.webinardetail.registration }</Text>
                    </Left>
              </CardItem>
        </Card>

        <Card>
                      <CardItem>   
                        <Left>
                      <Thumbnail source={noteicon} style={{ width: 30, height: 30 }}/>                          
                    <Text>{ this.state.webinardetail.note }</Text>
                    </Left>
              </CardItem>
        </Card>

        <Card>
        <CardItem button  onPress={() => this._pressWWW()}>    

         <Left>
        <Thumbnail source={wwwicon} style={{ width: 35, height: 35 }}/>         
                        <Text>{ this.state.webinardetail.website }</Text>
                        </Left>
                                   </CardItem>   
                    
        </Card>

        <Card>
                      <CardItem>   
                        <Left>
                      <Thumbnail source={organizericon} style={{ width: 30, height: 30 }}/>                          
                    <Text>Organizer : { this.state.webinardetail.organizer }</Text>
                    </Left>
              </CardItem>
        </Card>
        
       </Content>
        <Footer>
          <FooterTab>   
          <Button onPress={() => this.props.navigation.navigate("Choices")}
          >
                <Icon name='color-palette' />
                <Text>Choices</Text>
            </Button>
                
          </FooterTab>
        </Footer>
      </Container>
   );
  }
}
