//import { Ionicons } from '@expo/vector-icons';
//import { Image, StyleSheet } from "react-native";

import React, { Component } from "react";
import { Alert, } from "react-native";
import { Spinner, Thumbnail, Container, Header, Title, Left, Icon, Button, Body, Content,Text, Card, CardItem, Footer, FooterTab } from "native-base";
import * as Font from "expo-font";
import * as WebBrowser from 'expo-web-browser';

export default class Cities extends Component {
  state = {
    loading: true,
    cities:[],    
  }
  
  async componentDidMount() {
      await Font.loadAsync({
      Roboto: require('native-base/Fonts/Roboto.ttf'),      
      Roboto_medium: require('native-base/Fonts/Roboto_medium.ttf'),
      //Roboto: require('../assets/Roboto.ttf'),      
      //Roboto_medium: require('../assets/Roboto_medium.ttf'),      
      //...Ionicons.font,
    })

    this._mounted = true;

    var token=this.props.navigation.getParam('token');
    var domain=this.props.navigation.getParam('domain');        
    var imageurlhead=this.props.navigation.getParam('imageurlhead');
    //var link=this.props.navigation.getParam('link');
    
    this.setState({ loading: false, token: token, domain: domain, imageurlhead: imageurlhead,
    });

    
    await fetch('https://'+this.state.domain+'/cities', {
      method: 'GET',
      headers: {'Authorization': 'Token '+token}         
    })
    .then((res) =>
    {if (res.status === 401)
       {
         Alert.alert ("Error", "Invalid Token.\nPlease sign in again.",            
         [            
          {text: 'OK', onPress: () => this.props.navigation.navigate('Home')},
        ],          
        )
         
    }
    else{
      if (this._mounted) {
    res.json() 
      .then((data) => {
        this.setState({loading: false,
          cities: data
        }) // setState
      })   // then(data)   
    } // if this mounted
    this._mounted = false;
    } // else
    }) // if and res
    .catch(error => {        
      Alert.alert ("Alert", "The server may be down.  Please try again later.",            
        [            
         {text: 'OK', onPress: () => this.props.navigation.navigate('Home')},
       ],          
       )
    }) // catch error
  } // componentDidMount
 
 componentWillUnmount(){
    this._mounted = false;
  }
  //handleTouchItem = (customer) => {
  //  this.props.navigation.navigate("CustomerDetail", customer);
  //}
  render() {    
    if (this.state.loading) {
      return (
        <Container>
        <Header />
        <Content>
          <Spinner />
          <Spinner color='red' />
          <Spinner color='green' />
          <Spinner color='blue' />
        </Content>
      </Container>
      );
    }
    return (        
      <Container>
        <Header>     
          
        <Button transparent>
            <Icon name='home' />            
          </Button> 
          
        <Body>
                <Title>Choose a City</Title>
              </Body>             
            </Header>      
        <Content>
        
        {this.state.cities.map((city) => (        
        <Card key={city.id} >
            <CardItem button onPress={() => this.props.navigation.push("Choices", 
            {token:this.state.token, domain:this.state.domain, imageurlhead: this.state.imageurlhead,
            city:city.name})}>             
            <Left>
            <Thumbnail square source={{uri: 'https://'+this.state.imageurlhead+city.image}} 
            style={{ width: 25, height: 25 }}/>
             <Body>
                <Text>{city.name}</Text>                
                
                </Body>
                </Left>              
            </CardItem>            
        </Card>                 
       ))}
       </Content>

       <Footer>
          <FooterTab>          
          <Button onPress={() => WebBrowser.openBrowserAsync('https://www.vorrinize.com/')}>
            <Text>&copy; Vorrinize</Text>
          </Button>          
          </FooterTab>
        </Footer>
      </Container>
   );
  }
}

